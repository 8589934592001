import { posAxios } from "../utils/api";

export const getPaymentMethods = (config) =>
  posAxios.get("payment-methods", config);

export const summarizeCart = (
  {
    cartProducts: products,
    orderTypeId,
    paymentMethodId,
    note,
    accessToken,
    countryCode,
    phoneNumber,
    discountCustomer,
    externalGrandtotal,
    phone,
    voucherCode,
    discountType,
  },
  config
) =>
  posAxios.post(
    "summarize-cart",
    {
      products,
      orderTypeId,
      paymentMethodId,
      note,
      accessToken,
      countryCode,
      phoneNumber,
      discountCustomer,
      externalGrandtotal,
      voucherCode,
      phone,
      discountType,
    },
    config
  );

export const createInvoice = ({
  paymentMethodId,
  referenceCode,
  driver,
  customer,
  products,
  tableId,
  orderType,
  note,
  accessToken,
  mPin,
  externalGrandtotal,
  discountCustomer,
  voucherCode,
  remark,
  discountType,
}) =>
  posAxios.post("invoices", {
    paymentMethodId,
    referenceCode,
    driver,
    customer,
    products,
    tableId,
    orderType,
    note,
    accessToken,
    mPin,
    externalGrandtotal,
    discountCustomer,
    voucherCode,
    remark,
    discountType,
  });

export const getOutletTables = ({ outletId }) =>
  posAxios.get("tables", { params: { outletId } });

export const getOutletOrderTypes = ({ outletId }) =>
  posAxios.get(`outlets/${outletId}/order-types`);
